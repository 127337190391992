<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <div class="d-flex justify-space-between align-center mb-4">
        <v-btn small depressed color="fmq_gray" dark outlined to="/">
          <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
        >
      </div>

      <v-card elevation="1" class="pt-8 mt-4 mb-4 pa-4">
        <FilterAtendimentos
          @buscar="buscar($event)"
          @baixar="baixar($event)"
          :loading="loading"
          :loadingBtn="loadingBtn"
          :medicoAprovador="true"
        />
      </v-card>

      <v-card elevation="1" class="pt-8 mt-4 mb-4 pa-4">
        <v-progress-circular
          v-if="loading"
          :size="100"
          :width="4"
          color="fmq_black"
          indeterminate
          class="d-flex mx-auto py-8 mt-8"
        ></v-progress-circular>
        <div v-else>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab v-for="item in funcionarios" :key="item.funcionarioId">
              {{ item.funcionarioNome }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in funcionarios" :key="item.funcionarioId">
              <v-row dense>
                <v-col
                  :cols="item.periodoComparativo ? '6' : ''"
                  v-if="item.periodo"
                >
                  <div class="pa-4">
                    <p
                      class="font-weight-bold text-center body-1 text-uppercase mb-4"
                    >
                      {{ setDate(item.periodo.periodoInicio) }} a
                      {{ setDate(item.periodo.periodoTermino) }}
                    </p>
                    <LineChartDashboard
                      title="Média Mensal em Dias"
                      :dataChart="
                        setDataChartLine(item.periodo.mediaMensalEmDias)
                      "
                      class="mb-8"
                      :scaleY="item.escalaMediaMensal"
                    />
                    <TabelaAtendimentos
                      title="Média por assunto em dias"
                      keyTable="solicitacaoAssunto"
                      :data="item.periodo.mediaPorAssuntoEmDias"
                      class="mb-8"
                      textLeft="Média"
                    />
                    <TabelaAtendimentos
                      title="Média por especialidade em dias"
                      keyTable="medicosSolicitantesEspecialidadeNome"
                      :data="item.periodo.mediaPorEspecilidadeEmDias"
                      class="mb-8"
                      textLeft="Média"
                    />
                    <TabelaAtendimentos
                      title="Média por produtos em dias"
                      keyTable="produtoNome"
                      :data="item.periodo.mediaPorProdutoEmDias"
                      class="mb-8"
                      textLeft="Média"
                    />
                  </div>
                </v-col>
                <v-col cols="6" v-if="item.periodoComparativo">
                  <div class="pa-4">
                    <p
                      class="font-weight-bold text-center body-1 text-uppercase mb-4"
                    >
                      {{ setDate(item.periodoComparativo.periodoInicio) }} a
                      {{ setDate(item.periodoComparativo.periodoTermino) }}
                    </p>
                    <LineChartDashboard
                      title="Média Mensal em Dias - Comparativo"
                      :dataChart="
                        setDataChartLine(
                          item.periodoComparativo.mediaMensalEmDias
                        )
                      "
                      class="mb-8"
                      :scaleY="item.escalaMediaMensal"
                    />
                    <TabelaAtendimentos
                      title="Média por assunto em dias - Comparativo"
                      keyTable="solicitacaoAssunto"
                      :data="item.periodoComparativo.mediaPorAssuntoEmDias"
                      class="mb-8"
                      textLeft="Média"
                    />
                    <TabelaAtendimentos
                      title="Média por especialidade em dias - Comparativo"
                      keyTable="medicosSolicitantesEspecialidadeNome"
                      :data="item.periodoComparativo.mediaPorEspecilidadeEmDias"
                      class="mb-8"
                      textLeft="Média"
                    />
                    <TabelaAtendimentos
                      title="Média por produtos em dias - Comparativo"
                      keyTable="produtoNome"
                      :data="item.periodoComparativo.mediaPorProdutoEmDias"
                      class="mb-8"
                      textLeft="Média"
                    /></div
                ></v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <ErrorMessage v-if="showError" message="Dados não encontrados" />
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LineChartDashboard from "@/components/relatorios/LineChartDashboard.vue";
import TabelaAtendimentos from "@/components/relatorios/TabelaAtendimentos.vue";
import FilterAtendimentos from "@/components/relatorios/FilterAtendimentos.vue";
import {
  atendimentosMedicopArovadorJson,
  atendimentosMedicopArovadorExel,
} from "@/services/relatorio";
export default {
  name: "RelatorioAtendimentosMedicoAprovador",
  components: {
    LineChartDashboard,
    TabelaAtendimentos,
    FilterAtendimentos,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Relatórios",
        disabled: false,
        to: "",
      },
      {
        text: "Atendimentos pelo Médico Aprovador",
        disabled: true,
        to: "",
      },
    ],
    formData: {},
    loading: false,
    funcionarios: [],
    tab: null,
    loadingBtn: false,
    showError: false,
  }),
  methods: {
    async getData() {
      this.loading = true;
      this.showError = false;
      this.funcionarios = null;
      try {
        const resp = await atendimentosMedicopArovadorJson(this.formData);
        this.funcionarios = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.showError = true;
        console.error(e);
      }
    },
    async downloadData() {
      this.loadingBtn = true;
      try {
        const resp = await atendimentosMedicopArovadorExel(this.formData);
        window.open(resp.data.url, "_blank");
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        console.error(e);
      }
    },
    setDataChartLine(data) {
      const lineData = {
        labels: [],
        datasets: [
          {
            backgroundColor: "#f87979",
            borderColor: "#f87979",
            data: [],
          },
        ],
      };
      for (const mes in data) {
        lineData.labels.push(mes);
        lineData.datasets[0].data.push(data[mes]);
      }
      return lineData;
    },
    setDate(date) {
      const newDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return newDate;
    },
    buscar(event) {
      this.formData = event;
      this.getData();
    },
    baixar(event) {
      this.formData = event;
      this.downloadData();
    },
  },
};
</script>

<style></style>
